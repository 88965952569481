import React, { useState, useMemo, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { DropzoneArea } from 'material-ui-dropzone';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ShowModalButton } from './ShowModalButton';
import styles from './styles/form.module.css';
import dropzoneStyles from './styles/file-dropzone.module.css';

const initialValueItems = {
  applicationType: '',
  name: '',
  tel: '',
  email: '',
  check: false,
};

const privacyPolicyForApplication = (
  <p>当社では採用業務管理のため氏名・住所・電話番号等の個人情報を識別できる情報（以下、個人情報といいます）を人事総務部がお預かり致します。<br/>
  個人情報の提出に関しては原則任意といたします。ただし、提出をされない場合は採用の検討ができない場合もあります事をご了承下さい。なお、提出された個人情報に不足がある場合には、再提出を依頼する場合があります。<br/><br/>
  お預かりいたしました個人情報につきましては採用応募者への採用関連の提供・連絡のために利用し、法令に基づく場合の利用要請等を除き、第三者へ委託や提供することはありません。<br/><br/>
  なお、当社がお預かりしている個人情報に関して、ご本人より利用目的の通知、開示、訂正、追加、削除、利用の停止、消去及び第三者への提供の停止（以下「開示等」という）の求めがあった場合には、遅滞なく対応します。<br/><br/>
  また、開示等の請求手続きは下記の＜個人情報苦情及びご相談窓口＞までお問合せ下さい。<br/>
  開示等の内容を確認し、個人情報保護管理責任者より、弊社所定の申請書をお送り致します。<br/>
  必要に応じ、申請者本人であることを確認できる公的証明書（免許証・住民票等）の写しを申請書と併せて提出して頂く場合があります。<br/>
  代理人を通じての開示請求に際しては、本人の代理人に当たる者であることを説明する書類（委任状等）が必要となります。<br/>
  当社は、次のいずれかに該当する場合には、当該求めに関わる個人情報の全部又は一部について開示をお断りさせていただく場合があります。<br/>
  ・本人又は第三者の生命、身体、財産その他権利利益を害するおそれがある場合<br/>
  ・当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合<br/>
  ・法令に違反することとなる場合<br/><br/>
  
  ＜個人情報苦情及びご相談窓口＞<br/>
  バークリー・ジャパン株式会社<br/>
  代表取締役 井上 将之<br/>
  東京都新宿区新宿5-2-3 MRCビル7F<br/>
  03-5363-2090<br/>
  </p>
  )
  
export const ApplicationForm = () => {
  const [uploadFiles, setUploadFiles] = useState([]);
  const handleSetFile = (event) => {
    setUploadFiles([...event]);
  };
  const [modalState, setModalState] = useState(false);
  const [submittingState, setSubmittingState] = useState(false);
  const [sentState, setSentState] = useState(false);
  const [sentMessage, setSentMessage] = useState({
    messageTitle: '',
    message: <p></p>,
  });

  const data = useStaticQuery(graphql`
    query {
      allContentfulVjcHpRecruitPositionsAvailable(
        filter: { node_locale: { eq: "en-US" } }
      ) {
        edges {
          node {
            title
          }
        }
      }
    }
  `);

  const positionsAvailable = useMemo(() => {
    const currencies = [
      {
        value: '',
        label: '',
      },
    ];

    data.allContentfulVjcHpRecruitPositionsAvailable.edges.map((edge) => {
      return currencies.push({
        value: edge.node.title,
        label: edge.node.title,
      });
    });

    return currencies.reduce((a, v) => {
      if (
        !a.some(
          (e) =>
            (e.value === v.value && e.label === v.label) ||
            v.value === '募集なし' ||
            v.label === '募集なし'
        )
      ) {
        a.push(v);
      }
      return a;
    }, []);
  }, [data]);

  const formItems = [
    {
      type: 'text',
      name: 'name',
      label: '氏名*',
    },
    {
      type: 'tel',
      name: 'tel',
      label: '電話番号*',
    },
    {
      type: 'email',
      name: 'email',
      label: 'メールアドレス*',
    },
  ];

  const PHONE_REGULAR_EXPRESSION =
    '^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$';

  const validation = () =>
    Yup.object().shape({
      applicationType: Yup.string().required('必須項目です'),
      name: Yup.string()
        .max(100, '最大100文字で入力してください')
        .required('必須項目です'),
      tel: Yup.string()
        .matches(PHONE_REGULAR_EXPRESSION, '電話番号を入力してください')
        .max(15, '電話番号の形式で入力してください')
        .required('必須項目です'),
      email: Yup.string()
        .email('メールアドレスの形式で入力してください')
        .max(256, '電話番号の形式で入力してください')
        .required('必須項目です'),
      check: Yup.boolean().oneOf([true], '同意にチェックしてください'),
    });

  useEffect(() => {
    axios({
      method: 'post',
      url: '/mail/generateToken.php',
      withCredentials: true,
    });
  }, []);

  const submitHandler = (values) => {
    setSubmittingState(true);
    const submitData = new FormData();
    for (let i = 0; uploadFiles.length > i; i++) {
      submitData.append('file' + i, uploadFiles[i]);
    }
    for (let [key, value] of Object.entries(values)) {
      submitData.append(key, value);
    }
    axios({
      method: 'post',
      url: '/mail/application.php',
      headers: { 'content-type': 'multipart/form-data' },
      withCredentials: true,
      data: submitData,
    })
      .then((response) => {
        setSentState(true);
        setSentMessage({
          messageTitle: response.data.messageTitle,
          message: response.data.sent ? (
            <>
              <p>ご応募ありがとうございます。</p>
              <p>ご入力頂いたメールアドレスに自動返信いたしました。</p>
              <br />
              <p>メールの内容をご確認ください。</p>
            </>
          ) : (
            <p>{response.data.message}</p>
          ),
        });
        setSubmittingState(false);
      })
      .catch(() => {
        setSentState(true);
        setSentMessage({
          messageTitle: '送信エラー',
          message: <p>恐れ入りますが、もう一度お試し下さい。</p>,
        });
        setSubmittingState(false);
      });
  };

  const closeModalAction = () => {
    setModalState(false);
    setSentState(false);
  };

  const submitEventHandler = () => {
    document.getElementById('submitButton').click();
  };

  return (
    <>
      <div className={styles.contactFormWrap}>
        <Formik
          initialValues={initialValueItems}
          validationSchema={validation()}
        >
          {(props) => (
            <Form id='contactForm' noValidate>
              <div>
                <TextField
                  select
                  name='applicationType'
                  label='応募職種*'
                  value={props.values.applicationType}
                  onChange={props.handleChange('applicationType')}
                  onBlur={props.handleBlur('applicationType')}
                  SelectProps={{
                    native: true,
                  }}
                  helperText={
                    props.touched.applicationType &&
                    props.errors.applicationType
                  }
                  variant='outlined'
                >
                  {positionsAvailable.map((option, i) => (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </div>
              <div>
                {formItems.map((formItem, i) => (
                  <div className={styles.form} key={`application-form-${i}`}>
                    <TextField
                      type={formItem.type}
                      name={formItem.name}
                      label={formItem.label}
                      multiline={formItem.multiline}
                      rows={formItem.rows}
                      value={props.values[formItem.name]}
                      onChange={props.handleChange(formItem.name)}
                      onBlur={props.handleBlur(formItem.name)}
                      helperText={
                        props.touched[formItem.name] &&
                        props.errors[formItem.name]
                      }
                      variant='outlined'
                      key={`application-form-text-${i}`}
                    />
                  </div>
                ))}
                <div className={dropzoneStyles.dropzoneWrap}>
                  <DropzoneArea
                    dropzoneText='履歴書と職務経歴書を添付してください'
                    previewText=''
                    acceptedFiles={[
                      'application/pdf',
                      '.doc',
                      '.xls',
                      '.pages',
                      '.odt',
                      '.rtf',
                      'text/*',
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    ]}
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    useChipsForPreview
                    previewGridProps={{
                      container: {
                        spacing: 1,
                        direction: 'row',
                      },
                    }}
                    dropzoneClass={dropzoneStyles.dropzone}
                    showAlerts={false}
                    onChange={(event) => {
                      handleSetFile(event);
                    }}
                  />
                  {uploadFiles.length === 0 && props.submitCount > 0 && (
                    <p className={styles.fileErrorMessage}>
                      ファイルを選択してください
                    </p>
                  )}
                </div>
                <div className={styles.privacyPolicyWrap}>
                  <div className={styles.privacyPolicyLabel}>
                    プライバシーポリシー
                  </div>
                  <p className={styles.privacyPolicyMessage}>
                    {privacyPolicyForApplication}
                  </p>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='check'
                        checked={props.values.check}
                        onChange={props.handleChange('check')}
                        color='primary'
                      />
                    }
                    label={
                      <span className={styles.agree}>
                        プライバシーポリシーに同意しました。
                      </span>
                    }
                  />
                  {props.touched.check && (
                    <p className={styles.checkErrorMessage}>
                      {props.errors.check}
                    </p>
                  )}
                </div>
                <div>
                  <ShowModalButton
                    buttonTitle='送信'
                    buttonClassName={styles.showModalButton}
                    buttonFunction={
                      props.dirty && props.isValid && uploadFiles.length > 0
                        ? () => setModalState(true)
                        : props.handleSubmit
                    }
                  />
                </div>
                <button
                  type='submit'
                  id='submitButton'
                  onClick={() => submitHandler(props.values)}
                  aria-label='submit'
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className={`${styles.modalWrap} ${modalState && styles.showModal}`}>
        <div
          role='presentation'
          className={styles.mask}
          onClick={() => !submittingState && closeModalAction()}
        ></div>
        <div className={styles.modal}>
          <div
            role='presentation'
            className={styles.closeButton}
            onClick={() => !submittingState && closeModalAction()}
          >
            ×
          </div>
          <div id='modal' className={styles.modalMessageWrap}>
            <p className={styles.modalMessage}>送信してよろしいですか？</p>
          </div>
          <div className={styles.modalButtonWrap}>
            <button
              type='button'
              onClick={submitEventHandler}
              className={styles.submitButton}
            >
              <p className={styles.buttonMessage}>OK</p>
            </button>
          </div>
          <div
            className={`${styles.submitAfterModal} ${
              submittingState || sentState
                ? styles.submitting
                : styles.transparent
            }`}
          >
            <div
              className={
                !sentState ? styles.transparent : styles.sentMessageTitleArea
              }
            >
              <h2
                className={!sentState ? styles.hidden : styles.sentMessageTitle}
              >
                {sentMessage.messageTitle}
              </h2>
            </div>
            <div
              className={!sentState ? styles.hidden : styles.sentMessageArea}
            >
              <div className={!sentState ? styles.hidden : styles.sentMessage}>
                {sentMessage.message}
              </div>
            </div>
            <div className={sentState ? styles.transparent : styles.loaderWrap}>
              <div className={sentState ? styles.transparent : styles.loader}>
                Loading...
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
