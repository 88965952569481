import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import styles from '../components/styles/application.module.css';
import { HeaderImage } from '../components/HeaderImage.jsx';
import { ApplicationForm } from '../components/ApplicationForm';

export default () => {
  return (
    <Layout>
      <SEO title='応募フォーム'/>
      <HeaderImage
        title='APPLICATION FORM'
        subTitle='応募フォーム'
        fileName='recruit.jpg'
      />
      <div className={styles.contactContent}>
        <section className={styles.contactFormBox}>
          <section className={styles.boxContent}>
            <ApplicationForm />
          </section>
        </section>
      </div>
    </Layout>
  );
};
